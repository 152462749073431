import useRmaRepositories from "@/composables/useRmaRepositories"
import ListRmaState from "@/state/ListRma"

export default function useDashboardRepositories() {
  const { getRmaForOperationByPage, getRmaStatusList } = useRmaRepositories()
  const { state } = ListRmaState()

  const getRmaListData = async (parms) => {
    const result = await getRmaForOperationByPage(parms)
    state.listRmaData = result
    state.tableTotal = result.total
  }

  const getSomeOfRmaListData = async (parms) => {
    const result = await getRmaForOperationByPage(parms)
    return result;
  }

  const getRmaStatus = async () => {
    const result = await getRmaStatusList()
    state.statusList = result
  }

  return {
    getRmaListData,
    getRmaStatus,
    getSomeOfRmaListData
  }
}